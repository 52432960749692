import React, { Fragment } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import Dropdown from 'common/components/Dropdown';
import DragDropContainer, { makeDefaultDragDropElementWrapper, DragDropContainerType } from 'common/components/DragDropContainer';


class MyList extends React.Component {
  render() {
    const items = ['foo', 'bar', 'baz', 'buzz', 'qux'];
    const listItems = items.map(item => <span key={item}>{item}</span>);
    const wrappedListItems = makeDefaultDragDropElementWrapper(listItems);

    const onChangeOrder = (newItems) => {
      alert(`new order: ${newItems.join(', ')}`);
    };
    return (
      <DragDropContainer
        type={DragDropContainerType.LIST}
        items={items}
        className="whatever"
        onDrop={onChangeOrder}
        childElements={wrappedListItems}
        />
    );
  }
}

class MyForgeList extends React.Component {
  render() {
    const items = ['foo', 'bar', 'baz', 'buzz', 'qux'];
    const listItems = items.map(item => <span key={item}>{item}</span>);
    const wrappedListItems = makeDefaultDragDropElementWrapper(listItems);

    const onChangeOrder = (newItems) => {
      alert(`new order: ${newItems.join(', ')}`);
    };
    return (
      <DragDropContainer
        type={DragDropContainerType.FORGE_LIST}
        items={items}
        className="whatever"
        onDrop={onChangeOrder}
        childElements={wrappedListItems}
      />
    );
  }
}

class MyTable extends React.Component {
  render() {
    const tableRows = [
      {col1: 'Willie B.', col2: 'Cat', col3: 'Draggable'},
      {col1: 'Godzilla', col2: 'Monster', col3: 'Undraggable'},
      {col1: 'Fish', col2: 'Cat', col3: 'Draggable'},
      {col1: 'Caplin Rous', col2: 'Capybara', col3: 'Draggable'},
      {col1: 'Newt', col2: 'Dog', col3: 'Draggable'}
    ];
    const items = tableRows.map(row => row.col1);

    const wrappedListItems = tableRows.map(row => {
      const partialRow = (<Fragment>
        <td>{row.col1}</td>
        <td>{row.col2}</td>
        <td>{row.col3}</td>
      </Fragment>);

      const draggable = row.col1 !== 'Godzilla' && row.col1 !== 'Fish';
      const droppable = row.col1 === 'Fish';

      return {
        id: row.col1 + row.col2,
        className: 'whatever-row',
        dragAndDroppable: draggable,
        droppable: droppable,
        element: partialRow
      };
    });

    const onChangeOrder = (newItems) => {
      alert(`new order: ${newItems.join(', ')}`);
    };
    return (
      <table style={{borderCollapse: 'collapse'}}>
        <thead>
          <tr>
            <th>Drag Handle</th>
            <th>Name</th>
            <th>Species</th>
            <th>Dragability</th>
          </tr>
        </thead>
        <DragDropContainer
          type={DragDropContainerType.TABLE}
          items={items}
          className="whatever"
          onDrop={onChangeOrder}
          childElements={wrappedListItems}
          />
      </table>
    );
  }
}

class DragDropContainerDemo extends React.Component {
  constructor() {
    super();
    this.state = {
      containerType: MyList
    };
  }

  changeType = (newType) => {
    this.setState({
      containerType: newType
    });
  };

  render() {
    const DemoContainer = this.state.containerType;
    const typeOptions = [
      { title: 'List', value: MyList },
      { title: 'Forge List', value: MyForgeList },
      { title: 'Table', value: MyTable }
    ];
    return (
      <div>
        <div>
          <h5>Container Type:</h5>
          <Dropdown
            label="container type"
            onSelection={(option) => {
              this.changeType(option.value);
            }}
            options={typeOptions}
            value={this.state.containerType}
          />

        </div>
        <div>
          <DemoContainer />
        </div>
      </div>
    );
  }
}


const Wrapped = DragDropContext(HTML5Backend)(DragDropContainerDemo);  // eslint-disable-line new-cap

$(() => {
  ReactDOM.render(
    React.createElement(Wrapped),
    document.getElementById('component-demo')
  );
});
